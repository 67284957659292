import { define, ref } from 'heresy';
import { parseRoomUrlAndSubdomain } from '@whereby.com/core';

const boolAttrs = [
    "aec",
    "agc",
    "audio",
    "audioDenoiser",
    "autoHideSelfView",
    "autoSpotlight",
    "background",
    "bottomToolbar",
    "breakout",
    "callQualityMonitoring",
    "cameraAccess",
    "chat",
    "embed",
    "emptyRoomInvitation",
    "floatSelf",
    "help",
    "leaveButton",
    "locking",
    "localization",
    "logo",
    "lowData",
    "moreButton",
    "participantCount",
    "personality",
    "pipButton",
    "precallCeremony",
    "precallCeremonyCanSkip",
    "precallPermissionsHelpLink",
    "precallReview",
    "people",
    "reactions",
    "recording",
    "roomIntegrations",
    "screenshare",
    "settingsButton",
    "skipMediaPermissionPrompt",
    "subgridLabels",
    "timer",
    "toolbarDarkText",
    "topToolbar",
    "video",
];
define("WherebyEmbed", {
    oninit() {
        this.iframe = ref();
    },
    onconnected() {
        window.addEventListener("message", this.onmessage.bind(this));
    },
    ondisconnected() {
        window.removeEventListener("message", this.onmessage.bind(this));
    },
    observedAttributes: [
        "displayName",
        "minimal",
        "room",
        "subdomain",
        "lang",
        "metadata",
        "groups",
        "virtualBackgroundUrl",
        "avatarUrl",
        "externalId",
        "title",
        "cameraEffect",
        "emojiSkinTone",
        ...boolAttrs,
    ].map((a) => a.toLowerCase()),
    onattributechanged({ attributeName, oldValue }) {
        if (["room", "subdomain"].includes(attributeName) && oldValue == null)
            return;
        this.render();
    },
    style(self) {
        return `
    ${self} {
      display: block;
    }
    ${self} iframe {
      border: none;
      height: 100%;
      width: 100%;
    }
    `;
    },
    _postCommand(command, args = []) {
        if (this.iframe.current) {
            this.iframe.current.contentWindow.postMessage({ command, args }, this.roomUrl.origin);
        }
    },
    endMeeting() {
        this._postCommand("end_meeting");
    },
    knock() {
        this._postCommand("knock");
    },
    leaveRoom() {
        this._postCommand("leave_room");
    },
    openSettings(settingsPane = "media") {
        this._postCommand("open_settings", [settingsPane]);
    },
    startRecording() {
        this._postCommand("start_recording");
    },
    stopRecording() {
        this._postCommand("stop_recording");
    },
    startStreaming() {
        this._postCommand("start_streaming");
    },
    stopStreaming() {
        this._postCommand("stop_streaming");
    },
    startLiveTranscription() {
        this._postCommand("start_live_transcription");
    },
    stopLiveTranscription() {
        this._postCommand("stop_live_transcription");
    },
    toggleBreakout(open) {
        this._postCommand("toggle_breakout", [open]);
    },
    toggleCamera(enabled) {
        this._postCommand("toggle_camera", [enabled]);
    },
    toggleMicrophone(enabled) {
        this._postCommand("toggle_microphone", [enabled]);
    },
    togglePeople(enabled) {
        this._postCommand("toggle_people", [enabled]);
    },
    toggleScreenshare(enabled) {
        this._postCommand("toggle_screenshare", [enabled]);
    },
    toggleChat(enabled) {
        this._postCommand("toggle_chat", [enabled]);
    },
    onmessage({ origin, data, }) {
        if (!this.roomUrl || origin !== this.roomUrl.origin)
            return;
        const { type, payload: detail } = data;
        this.dispatchEvent(new CustomEvent(type, { detail }));
    },
    render() {
        const { avatarurl: avatarUrl, displayname: displayName, cameraeffect: cameraEffect, emojiskintone: emojiSkinTone, lang, metadata, externalid: externalId, minimal, room, groups, virtualbackgroundurl: virtualBackgroundUrl, title, } = this;
        let roomUrl, subdomain;
        try {
            ({ roomUrl, subdomain } = parseRoomUrlAndSubdomain(room, this.subdomain));
        }
        catch (error) {
            return this.html `Whereby: ${error instanceof Error ? error.message : "unknown error"}`;
        }
        this.roomUrl = roomUrl;
        Object.entries(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ jsApi: true, we: "1", iframeSource: subdomain }, (displayName && { displayName })), (lang && { lang: lang })), (metadata && { metadata: metadata })), (emojiSkinTone && { emojiSkinTone })), (externalId && { externalId })), (groups && { groups: groups })), (virtualBackgroundUrl && { virtualBackgroundUrl: virtualBackgroundUrl })), (avatarUrl && { avatarUrl: avatarUrl })), (cameraEffect && { cameraEffect: cameraEffect })), (minimal != null && { embed: minimal })), boolAttrs.reduce((o, v) => (this[v.toLowerCase()] != null ? Object.assign(Object.assign({}, o), { [v]: this[v.toLowerCase()] }) : o), {}))).forEach(([k, v]) => {
            if (!this.roomUrl.searchParams.has(k)) {
                this.roomUrl.searchParams.set(k, v);
            }
        });
        this.html `
      <iframe
        title=${title || "Video calling component"}
        ref=${this.iframe}
        src=${this.roomUrl}
        allow="autoplay; camera; microphone; fullscreen; speaker; display-capture; media-capture" />
      `;
    },
});
var index = { sdkVersion: "1" };

export { index as default };
